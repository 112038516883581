<template>
    <div class="pay-box" v-if="show">
        <div class="pay-main">
            <img class="close-icon" @click="closeBox" src="../../static/icon/close-icon.png">
            <div class="main">
                <div class="main-title">
                    <img src="../../static/icon/logo.png">
                    <span>财讯</span>
                </div>
                <div class="login-type">
                    <span class="type-span" :class="loginType === 0 ? 'type-span-active' : ''"
                        @click="loginType = 0">密码登录</span>
                    <span class="type-span" :class="loginType === 1 ? 'type-span-active' : ''"
                        @click="loginType = 1">验证码登录</span>
                    <!-- <span class="type-span" :class="loginType === 2 ? 'type-span-active' : ''"
                        @click="loginType = 2">微信登录</span> -->
                </div>
                <VueWxlogin v-if="loginType === 2" :appid="appId" scope="snsapi_login" :redirect_uri="redirectUri"
                    :state="state" :self_redirect="false"></VueWxlogin>
                <div class="input-box" v-if="loginType !== 2">
                    <img src="../../static/icon/user-icon.png">
                    <input v-model="userName" type="text" placeholder="请输入账号">
                </div>
                <div class="input-box" v-if="loginType === 0 && loginType !== 2">
                    <img src="../../static/icon/psd-icon.png">
                    <input v-model="password" :type="isShowpsw ? 'text' : 'password'" placeholder="请输入密码">
                    <img style="cursor: pointer;width: 16px;height: 12px;" @click="isShowpsw = !isShowpsw"
                        src="../../static/icon/yan-icon.png">
                </div>
                <div class="input-box" v-if="loginType === 1 && loginType !== 2">
                    <img src="../../static/icon/psd-icon.png">
                    <input v-model="code" type="text" placeholder="请输入验证码">
                    <span class="get-code" @click="getCode">{{ smsCodeTrue ? '获取验证码' : smsCodeTime + '秒后再次获取' }}</span>
                </div>
                <div class="remember-psd" v-if="loginType !== 2">
                    <!-- <el-checkbox v-model="checked"></el-checkbox>
                    <span>记住密码</span> -->
                    <span @click="goForgetPsw">忘记密码？</span>
                </div>
                <div class="btn" @click="login" v-if="loginType !== 2">
                    <span>登录</span>
                    <img src="../../static/icon/login-icon.png">
                </div>
                <div class="protocol-box" v-if="loginType !== 2">
                    <el-checkbox v-model="protocolChecked"></el-checkbox>
                    <span class="grey">未注册手机验证后自动登录，注册即代表同意</span>
                    <span class="grey blue" @click="goProtocol(1)">《用户协议》</span>
                    <span class="grey blue" @click="goProtocol(2)">《隐私协议》</span>
                </div>
                <div class="other-login-type" v-if="loginType !== 2">
                    <div class="other-login-type-len"></div>
                    <span>其他登录方式</span>
                    <div class="other-login-type-len"></div>
                </div>
                <img v-if="loginType !== 2" class="other-login-type-img" @click="chooseWechatLogin"
                    src="../../static/icon/wechat-icon.png">
            </div>
        </div>
    </div>
</template>

<script>

import { login, smsCode, getUserInfo } from '@/api/login.js'
import { validatePhoneNumber } from '@/utils/utils.js'
import { getInfo } from '@/api/homeRight.js'
import VueWxlogin from 'vue-wxlogin';
export default {
    name: "loginBox",
    props: {
        isShow: {
            type: Boolean
        }
    },
    watch: {
        isShow(e) {
            this.show = e
        }
    },
    components: {
        VueWxlogin
    },
    created() {
        this.show = this.isShow
        this.getWxLoginconfig()
    },
    data() {
        return {
            show: false,
            loginType: 0,//登录方式
            userName: '',//用户名
            password: '',//密码
            code: '',//验证码
            checked: false,//是否记住密码
            smsCodeTrue: true,//是否可以发送验证码
            smsCodeTime: null,//验证码倒计时
            protocolChecked: false,
            isShowpsw: false,//登录密码是否明文
            timeDown: null,//验证码倒计时计时器
            appId: '',
            // redirectUri: encodeURI('https://www.xdigitallife.com/transfer'),
            redirectUri: encodeURI(window.location.href.split(this.$route.fullPath)[0] + '/transfer'),
            state: ''
        }
    },
    methods: {
        /**
         * 切换微信扫码登录
         */
        chooseWechatLogin() {
            if (!this.protocolChecked) {
                this.$message.error('请先阅读并同意隐私保护指引和协议');
                return
            }
            this.loginType = 2
        },
        /**
         * 获取code
         */
        getWxLoginconfig() {
            getInfo('WxLoginConfig').then(res => {
                this.appId = res.appId
                this.state = res.state + this.$route.fullPath
            })
        },
        /**
         * 关闭弹窗
         */
        closeBox() {
            clearInterval(this.timeDown)
            this.$emit('close')
        },
        /**
         * 跳转隐私协议
         */
        goProtocol(type) {
            this.$router.push({ path: '/protocol', query: { type: type } })
        },
        /**
         * 获取验证码
         */
        getCode() {
            if (!this.smsCodeTrue) {
                this.$message.error('请' + this.smsCodeTime + '秒后再次发送');
                return
            }
            if (!validatePhoneNumber(this.userName)) {
                this.$message.error('请输入正确手机号');
                return
            }
            smsCode({
                phone: this.userName
            }).then(() => {
                this.$message.success('发送验证码成功')
                this.smsCodeTrue = false
                this.smsCodeTime = 60
                this.timeDown = setInterval(() => {
                    if (this.smsCodeTime !== 0) {
                        this.smsCodeTime--
                    } else {
                        clearInterval(this.timeDown)
                        this.smsCodeTrue = true
                        this.smsCodeTime = null
                    }
                }, 1000);
            })
        },
        /**
         * 忘记密码
         */
        goForgetPsw() {
            this.$router.push({ path: "/login", query: { type: 1 } });
        },
        login() {
            if (!this.protocolChecked) {
                this.$message.error('请先阅读并同意隐私保护指引和协议');
                return
            }
            if (!this.userName) {
                this.$message.error('请输入用户名');
                return
            }
            if (this.loginType === 0) {
                if (!this.password) {
                    this.$message.error('请输入密码');
                    return
                }
                login({
                    userName: this.userName,
                    password: this.password
                }).then(res => {
                    this.setToken(res);
                    getUserInfo().then(userInfo => {
                        this.setStorage('userId', userInfo.id);
                        this.$router.push({ path: "/refresh" });
                    })
                })
            } else {
                if (!validatePhoneNumber(this.userName)) {
                    this.$message.error('请输入正确手机号');
                    return
                }
                if (!this.code) {
                    this.$message.error('请输入验证码');
                    return
                }
                login({
                    phoneNumber: this.userName,
                    verificationCode: this.code
                }).then((res) => {
                    this.setToken(res);
                    getUserInfo().then(userInfo => {
                        this.setStorage('userId', userInfo.id);
                        this.$router.push({ path: "/refresh" });
                    })
                })
            }
        }
    }
}
</script>

<style scoped>
.pay-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 90;
}

.pay-main {
    width: 474px;
    height: 664px;
    background: #FFFFFF;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-icon {
    position: absolute;
    right: 8px;
    top: 24px;
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.pay-title {
    margin-top: 30px;
    color: #333333;
    font-size: 16px;
    text-align: center;
}

.main {
    width: 474px;
    height: 664px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-tips {
    color: #636363;
    font-size: 16px;
}

.main-title {
    color: #333333;
    margin-top: 12px;
    font-size: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
}

.main-title img {
    width: 39px;
}

.main-title span {
    color: #646464;
    font-size: 21px;
    margin-left: 8px;
}

.login-type {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 46px;
    margin-top: 65px;
    margin-bottom: 16px;
}

.login-type span {
    font-size: 16px;
    cursor: pointer;
}

.login-type .type-span {
    color: #999999;
}

.login-type .type-span-active {
    color: #DD0722;
}


.input-box {
    margin-top: 28px;
    width: 388px;
    border-radius: 28px;
    height: 56px;
    background-color: #F0F2F5;
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.input-box img {
    width: 28px;
    height: 28px;
}

.input-box input {
    margin-left: 10px;
    flex: 1;
    height: 26px;
    border: none;
    background-color: #F0F2F5;
}

.input-box .get-code {
    font-size: 16px;
    color: #4181B8;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
}

.remember-psd {
    margin-top: 14px;
    width: 388px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.remember-psd span {
    color: #999999;
    font-size: 14px;
    margin-left: 8px;
    cursor: pointer;
}

.btn {
    width: 388px;
    height: 46px;
    background-color: #DD0722;
    border-radius: 28px;
    margin-top: 20px;
    cursor: pointer;
}

.btn span {
    color: #FFFFFF;
    font-size: 18px;
    margin-right: 6px;
}

.btn img {
    width: 19px;
    height: 19px;
}

.protocol-box {
    width: 388px;
    display: flex;
    align-items: center;
    margin-top: 14px;
    flex-wrap: wrap;
}

.protocol-box .grey {
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999999;
}

.protocol-box .blue {
    cursor: pointer;
    color: #1186E1 !important;
    display: inline-block;
}

.other-login-type {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
}

.other-login-type-len {
    border-top: 1px solid;
    border-color: #ebeced;
    width: 160px;
}

.other-login-type span {
    color: #9196a1;
    font-size: 16px;
    margin-left: 16px;
    margin-right: 16px;
}

.other-login-type-img {
    margin-top: 28px;
    width: 42px;
    cursor: pointer;
}
</style>
